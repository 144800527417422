<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div class="w-75 mx-auto">
    <b-card>
      <b-card-body>
        <h1>{{ $t('Kết nối Fanpage của bạn') }}</h1>
        <div class="text-muted">
          {{ $t('Tiến hành kết nối Fanpage của bạn để có thể chat trực tiếp với khách hàng ngày trên Website') }}
        </div>
      </b-card-body>
    </b-card>
    <b-link v-for="(item, index) in listFanpage" :key="index" :to="`/chat/${item.id}`">
      <b-card>
        <b-card-body>
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <b-aspect aspect="16:9" class="d-flex align-items-center mr-2">
                <b-avatar size="lg" variant="none" src="@/assets/images/pages/logo-fb.svg" fluid class="d-block mx-auto" />
              </b-aspect>
              <div>
                <h3>{{ item.name }}</h3>
                <label>{{ item.category }}</label>
              </div>
            </div>
            <div>
              <b>Đi đến cuộc hội thoại</b>
              <feather-icon size="28" icon="ChevronRightIcon" class="ml-2" />
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-link>
    <b-card v-if="!connected">
      <b-card-header>
        <h3 class="w-100">
          {{ $t('Thực hiện kết nối với Fanpage') }}
        </h3>
        <div class="text-muted">
          {{ $t('Thực hiện kết nối với Fanpage bằng cách nhấn vào Kết nối') }}
        </div>
      </b-card-header>
      <b-card-body>
        <b-button
          v-b-modal.add-more-channels
          variant="primary"
          class="btn-icon rounded"
        >
          {{ $t('Kết nối ngay') }}
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard,
  BCardHeader,
  BCardBody,
  // BImgLazy,
  BAspect,
  BLink,
  BButton,
  BAvatar,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    // BImgLazy,
    BAspect,
    BLink,
    BButton,
    BAvatar,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      connected: true,
      listFanpage: [
        // {
        //   access_token: 'EAAdIOboJs5IBALH8oKD9jI0ZBhWr8wA1sqYNZBUBXiH0xeXfmr58szRKrYB70u8AYcOTNREZCrHdhyf83fXuSR5hMS6h80sFlQUJZARb1DujBRVxZCiSGDO9DxIkEMUZBG6gBTKT4shkuBdyGBJGLlwD8HpklJkzK6tTdiUCDkEch3Qk8luh3aFS3hqWiqR4JUeRMPDcHfZAxg8YUYEmZBGr',
        //   id: 101681312382465,
        //   name: 'Page test 22',
        //   category: 'Clothing store',
        // },
        // {
        //   access_token: 'EAAdIOboJs5IBAKWOBWO0a7KLKKHqSaZAy7JNq0xPMp1FgRx650JWu6y39MMtRrPRSnXozYSZBCFtSP37hIbTrtZC4A3VlXP5q4lqFMn0UZBlhdCyRr0nT7idM99oqsrCta4YvhCEGnbUN4jUflEe4eQ6DfgSH09RsGUikMWLBX2Uig8NbYbwfAnApWWVu3h22xidAKOc3732XX3q089m',
        //   id: 103537912189508,
        //   name: 'Page test',
        //   category: 'Clothing store',
        // },
      ],
    }
  },

  mounted() {
    this.getListFanpage()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.access_token_lazada !== '' && res.data.data.access_token_lazada !== null && res.data.data.access_token_lazada !== undefined) {
              this.channels.find(val => val.id === 1).connected = true
            }
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.channels.find(val => val.id === 2).connected = true
              // Load lấy số lượng sản phẩm đƯợc sync từ shopee
              const respon = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${JSON.parse(localStorage.getItem('siteID')).id}`)
              if (respon.status === 200) {
                if (respon.data.status === true) {
                  this.channels.find(val => val.id === 2).count = respon.data.data.length
                }
              }
            }
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== undefined) {
              this.channels.find(val => val.id === 4).connected = true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getListFanpage() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/get_list_fanpage?site_id=${JSON.parse(localStorage.getItem('siteID')).id}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // eslint-disable-next-line array-callback-return
            res.data.data.map(val => {
              this.listFanpage.push({
                id: val.id,
                access_token: val.access_token,
                category: val.category,
                name: val.name,
              })
            })
            // console.log(this.listFanpage)
          } else if (res.data.error.code === 190) {
            this.$router.push('/setting-fb?auth')
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async reconnectFB() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/fanpage-list`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.url
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
